import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js"

export default class extends Controller {
  static targets = ["select"]

  connect() {
    console.log("Hello, Stimulus!", this.element)
    // sleep for 1 second before running, setTimeout

    const existingOptions = [...this.selectTarget.options].map(option => ({
      value: option.value,
      label: option.text,
      selected: option.selected,
      disabled: option.disabled
    }));

    this.choices = new Choices(this.selectTarget, {
      removeItemButton: true,
      searchEnabled: true,
    })
  }

  disconnect() {
    // Destroy Choices.js instance when the controller is disconnected
    if (this.choices) {
      this.choices.destroy()
    }
  }
}
